<template>
  <div id="app">
    <a-scene device-orientation-permission-ui="enabled: true">
      <a-assets>
        <!-- Images. -->
        <img id="staging" :src="image">
      </a-assets>
      <!-- 360-degree image. -->
      <a-sky ref="staging"
             id="image-360"
             radius="10"
             :src="`#${staging}`"></a-sky>
      <!-- Link we will build. -->
      <!-- Camera + Cursor. -->
    </a-scene>
  </div>
</template>

<script>


  export default {
    name: 'app',
    components: {
    },
    directives: {
    },
    data() {
      return {
        staging: "staging",
        image: ""
      }
    },
    created() {
      const url = this.$route.query.image
      this.image = decodeURIComponent(url)
    },
    methods: {
    }
  }
</script>

<style lang="scss">
</style>
